/*
  Colors
*/
.primary-text-color{
  color: #ffffff !important;
}

.secondary-text-color{
  color: #d6d5d5
}

/*
  Text classes
*/

.home-link{
  padding: 2rem 0rem;
  font-size: 0.8rem;
  text-align: center;
}
.home-link > span{
  padding: 0.5rem 1rem;
  border-radius: 3px;
}

.home-link > a{
  padding: 0.5rem 1rem;
  border-radius: 3px;
  text-decoration: none;
  color: black;
}

.home-link > a:hover{
  background-color: rgb(73, 73, 73);
  color: #ffffff;
  
}


.home-link > span:hover{
  background-color: rgb(73, 73, 73);
  color: #ffffff;
  
}

.text-ul{
  border-bottom: 2px solid black;
  display: inline-block;
  padding: 2rem;
  margin-bottom: 3rem;
}

.draft-cat{
  background-color: rgb(84, 128, 255);
  display: inline-block;
  color: white;
  padding: 0.2rem 1rem;
  border-radius: 0.5rem;
  font-size: 0.9rem;
}

/*
  Shapes
*/
.circle{
  display: inline-block;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  background-color: rgb(146, 29, 214);
  border-radius: 2rem;
  margin-right: 1rem;
}

/*
  Colors
*/

.primary-background{
  background-color: rgb(84, 128, 255);
}

.primary-background:hover{
  background-color: #000 !important;
}



.center {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wave {
  width: 15px;
  height: 100px;
  /* background: linear-gradient(45deg, rgb(84, 128, 255), #fff); */
  background-color: rgb(84, 128, 255);
  margin: 10px;
  animation: wave 1s linear infinite;
  border-radius: 20px;
}
.wave:nth-child(2) {
  animation-delay: 0.1s;
}
.wave:nth-child(3) {
  animation-delay: 0.2s;
}
.wave:nth-child(4) {
  animation-delay: 0.3s;
}
.wave:nth-child(5) {
  animation-delay: 0.4s;
}
.wave:nth-child(6) {
  animation-delay: 0.5s;
}
.wave:nth-child(7) {
  animation-delay: 0.6s;
}
.wave:nth-child(8) {
  animation-delay: 0.7s;
}
.wave:nth-child(9) {
  animation-delay: 0.8s;
}
.wave:nth-child(10) {
  animation-delay: 0.9s;
}

@keyframes wave {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}